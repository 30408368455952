import { Text, Flex, Link, ListItem, UnorderedList } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { NavigationLink } from "./HeaderBar";

interface SubNavigationListProps {
  link: NavigationLink;
  isLargeScreen: boolean;
  collapseSubMenu: () => void;
  toggleSidebar: () => void;
}
export const SubNavigationList = (props: SubNavigationListProps) => {
  const { link, isLargeScreen, collapseSubMenu, toggleSidebar } = props;
  return (
    <UnorderedList
      flexDirection="column"
      position={[null, null, null, "absolute"]}
      zIndex={10000}
      top="16"
      left="0"
      marginInlineStart="0"
      listStyleType="none"
      backgroundColor="white"
      shadow={["none", null, null, "lg"]}
      width={["100%", null, null, "auto"]}
      fontSize="lg"
      border="1px"
      borderStyle={["none", null, null, "solid"]}
      borderColor="gray.100"
    >
      {link.subItems?.map((subLink, j) => {
        return (
          <ListItem width="100%" maxWidth="100%" key={`${j}-${subLink.to}`}>
            <Link
              className="sub-item"
              as={GatsbyLink}
              to={subLink.to}
              marginLeft="0"
              width="100%"
              _hover={{
                textDecoration: "none",
                backgroundColor: "zorba",
                color: "white",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (isLargeScreen) {
                  collapseSubMenu();
                } else {
                  toggleSidebar();
                }
              }}
            >
              <Flex
                paddingY="2"
                pl="8"
                paddingRight="16"
                textAlign="left"
                _hover={{
                  backgroundColor: "zorba",
                }}
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {subLink.name}
                </Text>
              </Flex>
            </Link>
          </ListItem>
        );
      })}
    </UnorderedList>
  );
};
