import { UnorderedList } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import theme from "../@chakra-ui/gatsby-plugin/theme";
import { navigationLinks } from "./HeaderBar";
import { NavigationItem } from "./NavigationItem";

interface NavigationPanelProps {
  currentRoute: string;
  isLargeScreen: boolean;
  toggleSidebar: () => void;
}
export const NavigationPanel = (props: NavigationPanelProps) => {
  const { currentRoute, isLargeScreen, toggleSidebar } = props;

  return (
    <NavigationList
      textAlign="center"
      display="flex"
      alignItems="center"
      listStyleType="none"
      margin="0"
    >
      {navigationLinks.map((link, i) => {
        const urlTokens = link.to.split("?");
        const url = urlTokens[0];
        const routeTokens = url.split("/");
        const route = routeTokens[1];
        const isSelected = currentRoute === route;
        const isSelectedLarge = isSelected && isLargeScreen;
        return (
          <NavigationItem
            key={`${i} - ${link.name}`}
            link={link}
            isSelectedLarge={isSelectedLarge}
            isLargeScreen={isLargeScreen}
            toggleSidebar={toggleSidebar}
          />
        );
      })}
    </NavigationList>
  );
};

const NavigationList = styled(UnorderedList)`
  height: 100%;
  li {
    height: 100%;
    width: 100%;
    position: relative;
  }
  li > div > * {
    :hover {
      text-decoration: none;
      font-weight: ${theme.fontWeights.semibold};
      color: ${theme.colors.brand};
    }
  }
  li > div > a {
    padding-left: ${theme.space["4"]};
    padding-right: ${theme.space["4"]};
  }
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    position: absolute;
    flex-direction: column;
    height: 100%;
    top: ${theme.space[4]};
    left: ${theme.space[4]};
    align-items: flex-start;
    padding: ${theme.space[2]};
    width: calc(100% - 1rem);
    li {
      min-width: 100%;
      height: auto;
    }
    li > div > a {
      padding-top: ${theme.space[2]};
      padding-bottom: ${theme.space[2]};
      padding-right: ${theme.space[2]};
      padding-left: ${theme.space[2]};
      font-size: ${theme.fontSizes.lg};
      text-align: left;
    }
  }
`;
