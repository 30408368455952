import { Flex, Image, Text, Link, FlexProps } from "@chakra-ui/react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

export const HomeLink = (props: FlexProps) => {
  const { site } = useStaticQuery(graphql`
    query sitetitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Flex height="100%" alignItems="center" {...props}>
      <Helmet title={site.siteMetadata.title} />
      <Link
        as={GatsbyLink}
        to={"/"}
        display="flex"
        height="100%"
        alignItems="center"
        _hover={{ textTransform: "none" }}
      >
        <Image
          display={["none", null, null, "flex"]}
          src="../../logo/logo.svg"
          alt="Star"
          width="10"
          ml={[null, null, null, "8"]}
        />
        <Text
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          color="zorba"
          fontWeight="bold"
          fontSize="2xl"
          ml={[null, null, null, "4"]}
        >
          Centenary Resources
        </Text>
      </Link>
    </Flex>
  );
};
