import { Flex, Heading } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Layout } from "./Layout";
import { Header } from "./Header";
import { HeaderBar, InternalLink } from "./HeaderBar";
import { Main } from "./Main";
import { InternalLinks } from "./InternalLinks";

interface NavigationPageProps {
  route: string;
  links: InternalLink[];
  headerRightContent?: ReactNode;
}

export const NavigationPage = (props: NavigationPageProps) => {
  const { route, links, headerRightContent } = props;
  return (
    <Layout>
      <Header>
        <HeaderBar rightContent={headerRightContent} />
      </Header>
      <Main pb="16" justifyContent="flex-start" alignItems="center">
        <Flex
          flexDirection="column"
          width="100%"
          maxWidth={["100%", null, null, "120ch"]}
        >
          <Heading
            color="delta"
            mx="6"
            fontSize="2xl"
            fontWeight="semibold"
            as="h2"
            mt="8"
            textTransform="capitalize"
          >
            {route}
          </Heading>
          <InternalLinks links={links} />
        </Flex>
      </Main>
    </Layout>
  );
};
