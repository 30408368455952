import { IconButton, Link } from "@chakra-ui/react";
import React from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import resourceCategories from "../../cms/collections/resourceCategories";
import { InternalLink } from "../../components/HeaderBar";
import { NavigationPage } from "../../components/NavigationPage";

const categoryToLink = (category): InternalLink => {
  const { id, description } = category;
  return {
    name: description,
    to: `/resources?category=${id}`,
  };
};

export const resourceSubLinks = resourceCategories.map(categoryToLink);

const IndexPage = () => {
  return (
    <NavigationPage
      route="view-resources"
      links={resourceSubLinks}
      headerRightContent={
        <IconButton
          aria-label="Upload Resource"
          colorScheme="red"
          icon={<FaCloudUploadAlt />}
          as={Link}
          href="https://forms.gle/gFcnT2Ftowtq1oEs8"
          isExternal
        />
      }
    />
  );
};

export default IndexPage;
