const resourceCategories = [
  {
    id: "EXE",
    name: "Exemplar",
    description: "Exemplar",
    exampleDescription: "Exemplar",
    order: 9,
  },
  {
    id: "FTD",
    name: "Featured",
    description: "Featured by World Center",
    exampleDescription: "Featured by World Center",
    order: 10,
  },
  {
    id: "VID",
    name: "Videos",
    description: "Video Resources",
    exampleDescription: "Video Resources",
    order: 40,
  },
  {
    id: "AUD",
    name: "Audio",
    description: "Audio Resources",
    exampleDescription: "Audio Resources",
    order: 50,
  },
  {
    id: "ANC",
    name: "Arts and Crafts",
    description: "Arts and Crafts",
    exampleDescription: "Arts and Crafts",
    order: 60,
  },
  {
    id: "EXB",
    name: "Exhibition",
    description: "Exhibition Resources",
    exampleDescription: "Exhibition Resources",
    order: 70,
  },
  {
    id: "PRE",
    name: "Presentation",
    description: "Presentation Resources",
    exampleDescription: "Presentation Resources (Powerpoint presentations etc)",
    order: 80,
  },
  {
    id: "PRI",
    name: "Printed",
    description: "Printed Resources",
    exampleDescription: "Printed Resources (Books, publications etc)",
    order: 90,
  },
  {
    id: "PRM",
    name: "Promotional",
    description: "Promotional Resources",
    exampleDescription:
      "Promotional Resources (Brochures, posters and similar things etc)",
    order: 100,
  },
  {
    id: "OTH",
    name: "Others",
    description: "Others",
    exampleDescription: "Others",
    order: 110,
  },
];

export const resourceCategoriesAll = [
  {
    id: "ALL",
    name: "All",
    description: "All Categories",
    exampleDescription: "All Categories",
    order: 0,
  },
  ...resourceCategories,
];

export default resourceCategories;
