import React from "react";
import { InternalLink } from "../../components/HeaderBar";
import { NavigationPage } from "../../components/NavigationPage";

export const planningSubLinks: InternalLink[] = [
  {
    name: "Letters and Guidance",
    to: "/guidance",
  },
  {
    name: "Inspiration and Ideas",
    to: "/inspirations",
  },
];

const IndexPage = () => {
  return <NavigationPage route="planning" links={planningSubLinks} />;
};

export default IndexPage;
