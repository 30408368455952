import React from "react";
import { InternalLink } from "../../components/HeaderBar";
import { NavigationPage } from "../../components/NavigationPage";

export const sharingSubLinks: InternalLink[] = [
  {
    to: "/submit-resource",
    name: "Submit your resource",
  },
  {
    to: "/share-plan",
    name: "Share your plan",
  },
];

const IndexPage = () => {
  return <NavigationPage route="share" links={sharingSubLinks} />;
};

export default IndexPage;
