import { Flex, IconButton, useBreakpointValue } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { ReactNode, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import theme from "../@chakra-ui/gatsby-plugin/theme";
import { planningSubLinks } from "../pages/planning";
import { sharingSubLinks } from "../pages/share";
import { resourceSubLinks } from "../pages/view-resources";
import { HomeLink } from "./HomeLink";
import { NavigationPanel } from "./NavigationPanel";

export interface InternalLink {
  to: string;
  name: string;
}
export interface NavigationLink extends InternalLink {
  subItems?: InternalLink[];
}

export const navigationLinks: NavigationLink[] = [
  {
    to: "/",
    name: "Home",
  },
  {
    to: "/view-resources",
    name: "View Resources",
    subItems: resourceSubLinks,
  },
  {
    to: "/planning",
    name: "Planning",
    subItems: planningSubLinks,
  },
  {
    to: "/stories-of-abdul-baha",
    name: "Stories of Abdu'l Baha",
  },
  {
    to: "/share",
    name: "Share",
    subItems: sharingSubLinks,
  },
  {
    to: "/about",
    name: "About Us",
  },
];

interface HeaderBarProps {
  rightContent?: ReactNode;
}
export const HeaderBar = (props: HeaderBarProps) => {
  const { rightContent } = props;
  const isLargeScreen = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
  });
  const [showSidebar, setShowSidebar] = useState(false);
  const currentRoute = getCurrentRoute();

  const toggleSidebar = () => {
    setShowSidebar((value) => !value);
  };

  return (
    <Flex
      height="16"
      width="100%"
      justifyContent={["space-between", null, null, "flex-start"]}
      alignItems="center"
    >
      {!isLargeScreen && (
        <IconButton
          backgroundColor="white"
          aria-label="Back"
          size="lg"
          mr="2"
          icon={<FaBars />}
          onClick={toggleSidebar}
        />
      )}
      <HomeLink marginRight={[null, null, null, "8"]} />
      <Flex
        alignItems="center"
        minWidth="12"
        height="100%"
        ml={["2", null, null, "0"]}
      >
        {(showSidebar || isLargeScreen) && (
          <ResponsiveNav as="nav" pr="2" onClick={toggleSidebar}>
            <Flex
              className="navigation-container"
              onClick={(e) => e.stopPropagation()}
              height="100%"
              alignItems="center"
            >
              <NavigationPanel
                currentRoute={currentRoute}
                isLargeScreen={isLargeScreen}
                toggleSidebar={toggleSidebar}
              />
            </Flex>
            <IconButton
              className="close-button"
              variant="ghost"
              size="lg"
              aria-label="Close Side Navigation"
              onClick={(e) => {
                e.stopPropagation();
                toggleSidebar();
              }}
              icon={<FaTimes />}
            />
          </ResponsiveNav>
        )}
        {rightContent}
      </Flex>
    </Flex>
  );
};

const getCurrentRoute = (level = 1) => {
  const url = typeof window !== "undefined" ? window.location.href : "";
  const urlTokens = url.split("?");
  const currentUrl = urlTokens[0];
  const tokens = currentUrl.split("/");
  const currentRoute = tokens.length > level + 1 ? tokens[level + 2] : null;
  return currentRoute;
};

const ResponsiveNav = styled(Flex)`
  height: 100%;
  align-items: center;
  .navigation-container {
    overflow-y: "visible";
  }
  li.main-item > a {
    padding-left: ${theme.space[4]};
    padding-right: ${theme.space[4]};
  }
  a.sub-item {
    margin-left: 0;
  }
  .close-button {
    display: none;
  }
  @media only screen and (max-width: ${theme.breakpoints.md}) {
    background-color: ${theme.colors.blackAlpha[500]};
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    .close-button {
      display: flex;
      position: absolute;
      top: 0;
      right: 20%;
    }
    .navigation-container {
      position: absolute;
      overflow-y: scroll;
      top: 0;
      left: 0;
      width: 80%;
      height: 100%;
      background-color: ${theme.colors.white};
    }
    .main-item {
      flex-direction: column;
    }
    a {
      margin-left: 0;
    }
  }
`;
