import { Flex, IconButton, Link, ListItem } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React, { ChangeEvent, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import theme from "../@chakra-ui/gatsby-plugin/theme";
import { NavigationLink } from "./HeaderBar";
import { SubNavigationList } from "./SubNavigationList";

interface NavItemProps {
  link: NavigationLink;
  isSelectedLarge: boolean;
  isLargeScreen: boolean;
  toggleSidebar: () => void;
}
export const NavigationItem = (props: NavItemProps) => {
  const { link, isSelectedLarge, isLargeScreen, toggleSidebar } = props;
  const [showSubMenu, setShowSubMenu] = useState(false);

  const toggleSubMenu = () => {
    setShowSubMenu((value) => !value);
  };

  const expandSubMenu = () => {
    setShowSubMenu(true);
  };

  const collapseSubMenu = () => {
    setShowSubMenu(false);
  };

  const handleClick = (e) => {
    if (link.subItems) {
      e.preventDefault();
      toggleSubMenu();
    }
  };
  const handleMouseEnter =
    link.subItems && isLargeScreen ? expandSubMenu : null;
  const handleMouseLeave =
    link.subItems && isLargeScreen ? collapseSubMenu : null;

  return (
    <ListItem
      display="flex"
      alignItems="center"
      className="main-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Flex
        onClick={handleClick}
        alignItems="center"
        minWidth="100%"
        height="100%"
      >
        <Link
          position="relative"
          style={
            isSelectedLarge
              ? {
                  fontWeight: theme.fontWeights.bold,
                  color: theme.colors.brand,
                }
              : {}
          }
          onClick={(e) => (link.subItems ? e.preventDefault() : null)}
          display="flex"
          whiteSpace="nowrap"
          width="100%"
          height="100%"
          alignItems="center"
          fontSize="lg"
          as={GatsbyLink}
          to={link.to}
          flex={1}
        >
          {link.name}
          {link.subItems && (
            <IconButton
              variant="ghost"
              aria-label="Expand Resources Menu"
              _hover={{
                backgroundColor: "transparent",
              }}
              _focus={{
                outline: "none",
                backgroundColor: "transparent",
              }}
              _active={{
                backgroundColor: "transparent",
              }}
              icon={<FaCaretDown />}
            />
          )}
        </Link>
      </Flex>
      {link.subItems && showSubMenu && (
        <SubNavigationList
          link={link}
          isLargeScreen={isLargeScreen}
          collapseSubMenu={collapseSubMenu}
          toggleSidebar={toggleSidebar}
        />
      )}
    </ListItem>
  );
};
