import { ListItem, OrderedList, Link, Heading } from "@chakra-ui/react";
import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { InternalLink } from "./HeaderBar";

interface InternalLinksProps {
  links: InternalLink[];
}
export const InternalLinks = (props: InternalLinksProps) => {
  const { links } = props;
  return (
    <OrderedList marginX="4">
      {links.map((link, i) => {
        return (
          <ListItem
            display="list-item"
            flexDirection="column"
            my="8"
            marginLeft="2rem"
            key={`${i} - ${link.name}`}
          >
            <Link textDecoration="underline" as={GatsbyLink} to={link.to}>
              <Heading as="h3" color="zorba" fontSize="lg" fontWeight="medium">
                {link.name}
              </Heading>
            </Link>
          </ListItem>
        );
      })}
    </OrderedList>
  );
};
